@use "../settings" as s;
@use "../tools" as t;

.c-radii {

    $self: &;

    &__label {
        margin-bottom: 10px;
        @include t.mm;
        font-size: 16px;
        line-height: 19px;
        color: s.$white;
    }

    &__array {
        display: flex;
        align-items: center;
        height: 60px;

        &--spread {
            display: flex;
            justify-content: space-between;
        }

    }

    &__wrap {
        margin-bottom: 0;
    }

    &__option {
        @include t.ml;
        font-size: 16px;
        line-height: 19px;
        color: s.$white;
    }

    .form-check-input:checked {
        background-color: #F041A6;
        background-image: none;
        border: 2px solid #FFFFFF;
        box-shadow: none;
    }

    &--brd {
        .form-check-input:checked {
            background-color: #169CFE;
        } 
    }

    @media(max-width: 991px){
        &__array {  
            &--spread {
                flex-direction: column;
                align-items: start;
                height: auto;

                #{$self}__wrap {
                    margin-bottom: 10px;
                }

            }
    
        }        
    }

}