// Colours

$black:          #000000;
$white:          #FFFFFF;

$flirt:          #BE018C;
$doger-blue:     #169CFE;
$cardinal-pink:  #80015E;
$brilliant-rose: #F041A6;
$governor-bay:   #3253B5;
