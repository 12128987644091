@use "../settings" as s;
@use "../tools" as t;

ul.errorlist {
    display: block;
    margin-top: 5px;
    margin-bottom: 0;
    padding-left: 0;
    li {
        display: block;
        margin-bottom: 10px;
        text-align: right;
        color: rgb(248, 215, 218);
    }
}