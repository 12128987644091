@use "../settings" as s;
@use "../tools" as t;

.c-select {

    &__label {
        margin-bottom: 10px;
        @include t.mm;
        font-size: 16px;
        line-height: 19px;
        color: s.$white;
    }

    &__select {
        border-radius: 10px;
        height: 60px;
        @include t.ml;
        font-size: 16px;
        color: s.$black;
    }
}