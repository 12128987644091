@use "../settings" as s;
@use "../tools" as t;

.c-button {

    a, button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 0;
        box-shadow: none;
        background-color: s.$white;
        border-radius: 35px;
        width: 320px;
        height: 70px;
        @include t.mb;
        font-size: 20px;
        line-height: 24px;
        text-decoration: none;
        color: s.$white;

        &:hover {
            text-decoration: none;
            color: s.$white;
        }
    }

    &--flirt {
        a, button {
            background-color: s.$flirt;
        }
    }

    &--doger-blue {
        a, button {
            background-color: s.$doger-blue;
        }
    }

    &--i-flirt {
        a, button {
            color: s.$flirt;
            &:hover {
                color: s.$flirt;
            }
        }
    }

    &--i-doger-blue {
        a, button {
            color: s.$doger-blue;
            &:hover {
                color: s.$doger-blue;
            }
        }
    }

    &--center {
        text-align: center;
    }

    @media(max-width: 1199px){
        a, button {
            width: 273px;
        }
    }

}