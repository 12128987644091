@use "../settings" as s;
@use "../tools" as t;

.s-lower {

    $self: &;

    display: none;
    background-color: s.$cardinal-pink;
    padding-top: 100px;
    padding-bottom: 90px;

    &__body {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__title {
        margin-bottom: 15px;
        @include t.ob;
        font-size: 35px;
        line-height: 48px;
        color: s.$white;
    }

    &__subtitle {
        margin-bottom: 45px;
        @include t.ob;
        font-size: 35px;
        line-height: 48px;
        color: s.$brilliant-rose;
    }

    &__form-dev {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(7, 1fr);
        grid-column-gap: 33px;
        grid-row-gap: 27px;
    }

    &__form-dev > *:nth-child(13){
        grid-column: span 2;
    }

    &__form-dev > *:nth-child(14){
        grid-column: span 2;
    }

    &__form-int {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 33px;
        grid-row-gap: 27px;
    }

    &__form-int > *:nth-child(11){
        grid-column: span 2;
    }

    &__form-int > *:nth-child(12){
        grid-column: span 2;
    }

    &__privacy {
        margin-top: 35px;
        width: 100%;
        @include t.mm;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: s.$white;

        p {
            margin-bottom: 19px;
        }

        a {
            text-decoration: underline;
            color: s.$white;

            &:hover {
                text-decoration: underline;
                color: s.$white;
            }
        }
    }

    &--brd {
        background-color: s.$governor-bay;

        #{$self}__subtitle {
            color: s.$doger-blue;
        }
    }

    &--show {
        display: block;
    }

    @media(max-width: 1199px){
        padding-top: 30px;
        padding-bottom: 35px;

        &__title {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 30px;
        }
        
        &__subtitle {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 30px;
        }

        &__form-dev {
            display: block;
            .c-field, .c-radii, .c-select, .c-button {
                margin-bottom: 20px;
            }
        }

        &__form-int {
            display: block;
            .c-field, .c-radii, .c-select, .c-button {
                margin-bottom: 20px;
            }
        }

    }
}