// Tools – Globally used mixins.

@mixin ob {
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
}

@mixin ml {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

@mixin mm {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

@mixin mb {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}