@use "../settings" as s;
@use "../tools" as t;

.s-success {

    $self: &;

    background-color: s.$cardinal-pink;
    padding-top: 95px;
    padding-bottom: 95px;

    &__body {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__title {
        margin-bottom: 15px;
        @include t.ob;
        font-size: 35px;
        line-height: 48px;
        color: s.$white;
    }

    &__subtitle {
        @include t.ob;
        font-size: 35px;
        line-height: 48px;
        color: s.$brilliant-rose;
    }

    &--brd {
        background-color: s.$governor-bay;
        #{$self}__subtitle {
            color: s.$doger-blue;
        }
    }

    @media(max-width: 1199px){
        padding-top: 30px;
        padding-bottom: 350px;

        &__title {
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 30px;
        }
        &__subtitle {
            font-size: 18px;
            line-height: 30px;
        }   
    }

}