@use "../settings" as s;
@use "../tools" as t;

.s-upper {
    position: relative;
    display: flex;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-top: 25px;
    padding-bottom: 25px;

    background-image: url('/static/img/upper_background.png');
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('/static/img/upper_background@2x.png');
    }

    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 25px;
        background: rgb(217,1,115);
        background: linear-gradient(90deg, rgba(190,1,140,1) 0%, rgba(22,156,254,1) 100%);
        content: '';
    }

    &__middle {
        width: 100%;
    }

    &__header {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__logo {
        margin-bottom: 65px;
        img {
            width: 378px;
        }
    }

    &__title {
        margin-bottom: 50px;
        @include t.ob;
        font-size: 54px;
        line-height: 64px;
        color: s.$white;
    }

    &__wrapper {
        position: relative;
        width: 100%;
        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 65.78%;
            right: 0;
            background: rgb(217,1,115);
            background: linear-gradient(90deg, rgba(217,1,115,0.22) 0%, rgba(0,96,148,0.22) 100%);
            content: '';

            @media(min-width: 1180px){
                left: calc(50vw + 172px);
            }
        }
    }

    &__body {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__column-1 {
        width: 57.27%;
    }

    &__column-2 {
        width:34.22%;
    }

    &__content {
        @include t.ml;
        font-size: 23px;
        line-height: 35px;
        color: s.$white;

        p {
            margin-bottom: 35px;
        }

        a {
            text-decoration: underline;
            color: s.$white;

            &:hover {
                text-decoration: underline;
                color: s.$white;
            }
        }
    }

    &__cta {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding-left: 60px;
    }

    &__subtitle {
        margin-bottom: 27px;
        @include t.ob;
        font-size: 35px;
        line-height: 48px;
        text-align: center;
        color: s.$white;
    }

    &__button {
        margin-bottom: 20px;
    }

    @media(max-width: 1199px){

        &__logo {
            margin-bottom: 30px;
            img {
                width: 165px;
            }
        }

        &__body {
            flex-direction: column;
        }

        &__column-1 {
            width: 100%;
        }

        &__column-2 {
            width: 100%;
        }

        &__cta {
            margin-left: -20px;
            width: calc(100% + 40px);
            padding-left: 0;
            padding-top: 30px;
            padding-bottom: 30px;
            align-items: center;
            background: rgb(217,1,115);
            background: linear-gradient(90deg, rgba(217,1,115,0.22) 0%, rgba(0,96,148,0.22) 100%);
        }

        &__title {
            margin-bottom: 15px;
            font-size: 20px;
            line-height: 30px;
        }

        &__wrapper {
            &:before {
                content: none;
            }
        }

        &__content {
            font-size: 14px;
            line-height: 19px;
 
            p {
                margin-bottom: 19px;
            }
        }

        &__subtitle {
            margin-bottom: 45px;
            font-size: 18px;
            line-height: 30px;
        }

    }
}